/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
.caso{
margin-left: 5vw;
margin-right: 5vw;
margin-top: 5vw;
margin-bottom: 5vw;

}
/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

.opacityBackground{
  opacity: 0.6;
}

.blackColor{
  color: #000000 !important;
  
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #ffffff;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}


input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
/*
nav.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  /*z-index: 9999;*/
  /*
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}*/
.social-icon {
    display: inline-block;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 3vw;
}

.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
*/
/************ Banner Css ************/
.banner {
  padding: 0rem 0 5rem 0;
  /*background-position: top center;*/
  background-image: url('./assets/img/backgroundBeach3.webp');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: top; /* Ajusta los valores como desees */
}

.carousel-indicators li { border-top: unset; width: 10px; border-bottom: unset; border: 1px solid transparent; border-left-width: 0; border-right-width: 0; border-radius: 10px; background-color: rgba(169, 29, 42, 0.4); height: 10px; width: 20px;}  .carousel-indicators .active { border-top: unset; border-bottom: unset; border: 1px solid transparent; border-left-width: 0; border-right-width: 0; border-radius: 10px; background-color: #A91D2A; height: 10px; width: 50px}

.banner .dark {
  --tw-main: transparent;
  --tw-hover: #e0e0e0;
  --tw-selected: #e0e0e0;
  --tw-border: #fff;
  --tw-gray: #555555;
  --tw-bg: #fff;
  color: #555555;
  --tw-size: border-box;
  --tw-width: fit-content;
  border-width: 0px!important;
}
.budget .dark {
  --tw-main: transparent;
  --tw-hover: #e0e0e0;
  --tw-selected: #e0e0e0;
  --tw-border: #fff;
  --tw-gray: #555555;
  --tw-bg: #fff;
  color: #555555;
  --tw-size: border-box;
  --tw-width: fit-content;
  border-width: 0px!important;
}

.css-1im77uy-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 0px!important;
  box-sizing: border-box;
}
.rmsc .dropdown-heading {
  position: relative;
  padding: 0 var(--rmsc-p);
  display: flex;
  align-items: center;
  width: 225px!important;
  height: 38px!important;
  cursor: default;
  outline: 0;
  }
.white-background .MuiInputBase-root {
    background-color: white !important;
}

.budget .fields {
    border-width: 0px!important;
  
    padding: 20px 10px; line-height: 28px;  --main: #4285f4;
    --hover: #f8f7f6;
    --selected: #1d1915;
    --border: #333333;
    --gray: #555555;
    --bg: #fff;
    color: #555555;
    background-color: #fff;
    font-size: 1rem;
    font-family: 'Centra', sans-serif !important;
    box-sizing: border-box;
    border-radius: 0.375rem;
    border-color: transparent;
    align-items: center;
    cursor: default;
    display: flex;
   font-weight: 400;
   height: 38px;
   line-height: 24px;
   padding-left: 10px;
   padding-right: 10px;
   position: relative;
   text-align: start;
   width: fit-content;
  }
  .border {
    border: 1px solid #E2B60A; /* Color del borde, puedes cambiarlo según lo necesites */
    padding: 10px;             /* Espaciado interno para que el contenido no esté pegado al borde */
    border-radius: 5px;        /* Bordes redondeados, puedes ajustar según lo necesites o eliminarlo si no quieres bordes redondeados */

  }
  .border3 {
    border: 1px solid #000000; /* Color del borde, puedes cambiarlo según lo necesites */


  }
  
  .border2 {
    border: 1px solid #E2B60A; /* Color del borde, puedes cambiarlo según lo necesites */
    border-radius: 5px;        /* Bordes redondeados, puedes ajustar según lo necesites o eliminarlo si no quieres bordes redondeados */

  }
  
#customDatePicker .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75) !important;
    background-color: white !important; 
    padding: 0 4px !important;
}

#customDatePicker .MuiInputLabel-filled {
    transform: translate(12px, 20px) scale(1) !important;
    background-color: white !important;
    padding: 0 4px !important;
}

#customDatePicker .MuiFilledInput-input {
    padding-top: 25px !important;
    padding-bottom: 10px !important;
}

.common-input-size .MuiInputBase-root {
  height: 40px !important; /* o la altura que desees */
  padding: 10px; /* o el padding que consideres adecuado */
  width: auto; /* Adjusts to content */
  min-width: 100% !important;
  max-width: 250px !important; /* To ensure the width doesn’t exceed parent container */
  margin: 0 auto;
  border-width: 0px!important;


}

.banner .fields {
  border-width: 0px!important;

  padding: 20px 10px; line-height: 28px;  --main: #4285f4;
  --hover: #f8f7f6;
  --selected: #1d1915;
  --border: #333333;
  --gray: #555555;
  --bg: #fff;
  color: #555555;
  background-color: #fff;
  font-size: 1rem;
  font-family: 'Centra', sans-serif !important;
  box-sizing: border-box;
  border-radius: 0.375rem;
  border-color: transparent;
  align-items: center;
  cursor: default;
  display: flex;
 font-weight: 400;
 height: 38px;
 line-height: 24px;
 padding-left: 10px;
 padding-right: 10px;
 position: relative;
 text-align: start;
 width: fit-content;
  margin-top: 1rem;
}
.error-message {
  color: #f70404; /* Cambiar este valor al color azul fuerte que desees */
  font-size: 14px;
  margin-top: 10px;
}
.privacy-policy-container {
  background-color: #000;
  color: #fff;
  padding: 20px; /* O ajusta el espacio de padding como desees */
}

.privacy-policy-container h1 {
  color: #fff; /* Si quieres cambiar el color del título */
}

.privacy-policy-container p {
  /* Ajusta el estilo de los párrafos como desees */
}

.banner h1 {
  font-size: calc(2em + 5vw);
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  margin-top: 2vw;
  margin-bottom: 2vw;
  margin-right: 4vw;
  

}

.rayado{
  color: #E2B60A;
  font-size: 15px;
  letter-spacing: 0.8px;
  align-items: center;
  margin-bottom: 2vw;
  text-decoration: underline;
}
.button-wrapper {
  display: inline-block;
  overflow: hidden;
}

.button-wrapper button {
  pointer-events: none;
}

.button-wrapper a {
  display: block;
  pointer-events: auto;
}

.banner button {
  color: #E2B60A ;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.whiteColor{
  color: #fff !important ; 
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  
    height: 50rem;
    max-width: 50rem;
  
}
/* Estilo por defecto para pantallas grandes */
.logo-fixed {
  width: 100px !important;
  height: 100px !important;
}

/* Estilo específico para pantallas con un ancho máximo de 768px (típicamente dispositivos móviles en modo vertical) */
@media (max-width: 768px) {
  .logo-fixed {
    width: 50px !important; /* O el tamaño que desees para móviles */
    height: 50px !important;
  }
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
/************ Cards Css ************/

.cards {
  scroll-padding-top: -10px; /* Ajusta el valor según tus necesidades */
  background:transparent;
  border-radius: 64px;
  text-align: center;
  margin-top: -10px;  
}

/************ budget Css ************/

.budget {
  padding: 0px 0 0px 0;
  position: relative;
  
}
.budget2 {
  padding: 0px 0 0px 0;
  position: relative;
  margin-top: 2rem; /* Ajusta el valor según tus necesidades */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  height: 100%; /* Might need to adjust this based on your layout */

}
.budget2-bx {
  border-radius: 10px; /* Ajusta el valor según la curvatura que desees */

  background: #192839;
  text-align: left;
  padding: 20px 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  

}
.testContainer{
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 2fr 1fr 2fr;
  min-width: 300px;
  /*justify-items: start;
  align-items: center;*/
  grid-template-areas: 
  'from flight to '
  'datafrom flight datato ';
}
.itemFrom{

grid-area: from;
}


.itemTo{

  grid-area: to;
  
  
  }
.itemStops{

  grid-area: stops;
    
  }
  .itemFlow{

    grid-area: flight;
    justify-self: start;
    align-self: center !important;



    
    }
    .itemDate1{

      grid-area: datafrom;
      align-self: end;
      
      }
      .itemDate2{

        grid-area: datato;
        align-self: end;

        
        }
      .itemHours{

        grid-area: hour;
        align-self: center;

          
        }

.overflowFlight{
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align vertically */
  justify-content: center; /* Center align horizontally */
}
.css-4jnixx-MuiStack-root {
  overflow-y: hidden !important;
  overflow-x: hidden !important;

}

.googlemap-bx {
  background: transparent;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -120px;
  max-width: 100%;
}

.budget h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
}
.budget h4 {

  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.budget-bx {
  background: #192839;
  text-align: center;
  padding: 20px 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  

}

.advanced-bx {
  background: #192839 !important;
  border-radius: 10px !important;
  color: white !important;
  text-align: center !important;


  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}


.itinerary-bx {
  background: #192839;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -100px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 560px;

}
/* Estilos para dispositivos móviles */
/* app.css */

div.flightsContainer {
  display: flex;
  flex-direction: column; 
}

/* Estilos para pantallas más grandes */
@media (min-width: 768px) {
  div.flightsContainer {
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* Sin comillas */
  }
}



.yellowBackground{
  background-color: #E2B60A !important;

}
.tips-bx {
  background: #E2B60A;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -100px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

}
.public-bx {
  background: #449e51;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -100px;
  max-width: 100%;

}

.tooltip {
  z-index: 1000; /* Un número alto para asegurarte de que esté encima de todo */
  max-width: 100%; /* o cualquier otro valor que prefieras */
  opacity: 1; /* Set the opacity to 1 to remove transparency */
  background: rgb(226, 182, 10); /* Set the desired background color */

}
.select-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.select-wrapper label {
  position: absolute;
  top: -20px;
  left: 5px;
  background-color: white;
  padding: 0 5px;
  font-size: 0.9em;
}
.prueba{
  color: #fffcfc;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}


.bottomGrid{
  
  bottom: 0 !important;
  place-content: bottom !important;

}
.splitScreen {
  display: grid;
  margin-bottom: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.topPane {
  grid-column: 1;
  grid-row: 1;
}

.containertopPanel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topPanel {
  /* Otros estilos para el componente */
  width: 100%;
}
.city-and-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.button-container {
  display: flex;
  justify-content: flex-end;
}

.custom-favorite-icon {
  color: pink;
}


.popup-container {
  min-width: 200px;
  max-width: 200px;

}
.bottomPane {
  grid-column: 2;
  grid-row: 1;
}
.news-bx {
  background: #c3c3c3;
  border-radius: 10px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -100px;
  color: #000000;
  max-width: 100%;
}

.news-bx2 {
  text-align: left;
}

.budget h2 {
	font-size: 35px;
	font-weight: 700;
}
.budget p {
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin-top: 0.5vw;
}

/************ Skills Css ************/
.skill {
  padding: 20px 0 50px 0;
  position: relative;
  scroll-padding-top: 90px; /* Ajusta el valor según tus necesidades */
  margin-top: 90px; /* Ajusta el valor según tus necesidades */

}
.flag-icon{
  width: 50% !important;
  min-width: 40px;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  border-radius: 50%;
  aspect-ratio: 1 / 1;
}
.flag-icon2{
  width: 15% !important;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

@media (max-width: 768px) {
  .aquitest > div {
    place-self: stretch;

  }
}

.flag-icon4{
  width: 100% !important;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  border-radius: 50%;
  aspect-ratio: 1 / 1 !important;
}
.stack-container {
  background-color: #fff; /* Cambiar el color de fondo según sea necesario */
  justify-content: flex-end;
}
.country-icon-img {
  width: 50% !important;
  min-width: 40px;
  height: auto;

  aspect-ratio: 1 / 1;
}

.country-icon {
  margin-right: 10px; /* Ajusta el margen según tus preferencias */
}

.country-text {
  flex-grow: 1;
}
.country-box {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}
.city-box {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}
.leaflet-routing-container * {
  font-size: 0 !important;
}

.skill h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
}
.skill h4 {

  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.skill-bx {
  background: #192839;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.center-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-iconMargin{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3vw;
  margin-right: 3vW;
  margin-left: 3vw;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}

.skill h2 {
	font-size: 35px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin-top: 0.5vw;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.zindex{
  z-index: 9999;
}

/************ Projects Css ************/
.project {

  padding: 20px 0 50px 0;
  position: relative;
  scroll-padding-top: 90px; /* Ajusta el valor según tus necesidades */
  margin-top: 90px; /* Ajusta el valor según tus necesidades */

}
.buttonFloatSpecial {

}
.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #000000 !important;
}
.css-zs07ro-MuiInputBase-root-MuiFilledInput-root.Mui-error:before, .css-zs07ro-MuiInputBase-root-MuiFilledInput-root.Mui-error:after {
  border-bottom-color: transparent !important;
}
/* Estilos específicos para dispositivos móviles usando media queries */
@media (max-width: 768px) {
  .buttonFloatSpecial {
    margin-right: 120px !important; /* Ajusta el margen para dispositivos móviles */
    margin-top: -20px !important;

  }
}
.css-160unip {

  overflow-y: hidden !important;
}
.project h2 {
	font-size: 35px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
    font-size: 15px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}
.transport-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
}
.transport-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
}
.stopover-item {
  /* Elimina esto si estás estableciendo una altura explícita */
  height: auto; 
  /* Asegúrate de que tu caja se expanda para incluir todo su contenido */
  overflow: auto; 
}
.widget-container {
  height: 50%; /* Ajusta este valor según tus necesidades */
  overflow: auto; /* Agrega un scroll si el contenido es más grande que el contenedor */
}

.transport-icon {
  flex-basis: 100%;
}

@media screen and (min-width: 2000px) {
  .transport-icon {
    flex-basis: auto;
  }
}


.transport-icons > div {
  margin-right: 10px; /* Ajusta el espacio entre los iconos según sea necesario */
}
.transport-icons2 {
  display: flex;
  justify-content: center;
  align-items: center;
}


.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.head-text {
  position: relative;
  text-align: center;
}
.a{
  font-weight: 700;
  font-size:  18px;
}
.text-on-image {
 position:absolute;
 right: 20%;
 left: 20%;
 bottom: 35%;
 top: 40%;
 text-align: center;
}
.weather{
  position: absolute;
  top: 10px;
  right: 10px;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  opacity: 1;  /* O cualquier estilo que esté utilizando en el estado normal */
}
.leaflet-routing-container {
  pointer-events: none !important;
}
.leaflet-marker-icon {
  z-index: 9999 !important; /* Asegúrate de que este valor es mayor que el de los otros paneles */
}

.weather2{
  position: absolute;
  top: 10px;
  left: 10px;
}

.proj-imgbx2 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx {
  color: #fff;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
}
.bg-grey {
  background: #ccc;
}
.bg-blue {
  background: #b2eaeb;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #2290ac -5.91%, #192839 111.58%);
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
    left: 0%;

}
.test{
  margin-top: -9rem!important;
}
.proj-imgbx:hover::before {
  height: 0%;

}
.testeo {
  overflow-x: hidden;
}
.proj-txtx {
  transition: 0.5s ease-in-out;
  opacity: 1;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 0;

}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/

/************ CountryDetail Css ************/
.countryDetail {
  padding: 0rem 0 5rem 0;
  /*background-position: top center;*/
  background-size:cover;
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: top;
}
.right-icons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.placeCenter{
  place-content: center;
  margin-top: 3vw;
}

.placeCenter2{
flex-direction: row;
align-content: space-around;
place-content: center!important;


}
.placeCenter3{
  place-content: center;
  position: inherit;
}
.placeCenter4{
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}

.placeOver2{
  position: absolute;
  top: 130px;
  left: -50px;
  overflow-x: hidden;
  width: fit-content;
}
.placeOver3{
  position: absolute;
  top: 190px;
  left: -50px;
  overflow-x: hidden;
  width: fit-content;
}
.placeOver7{
  position: absolute;
  top: 40px;
  right: -50px;
  max-width:max-content;
  overflow-x: hidden;
  width: fit-content;
}
.select-container {
  display: flex;   /* Activar flexbox */
  justify-content: space-between; /* Separar los elementos al máximo posible */
  align-items: center;  /* Centrar los elementos verticalmente */
  min-width: 250px;  /* o el valor que desees */

}
.placeOver4{
  position: absolute;
  top: 100px;
  right: -50px;
  max-width:max-content;
  overflow-x: hidden;
  width: fit-content;
}
.placeOver5{
  position: absolute;
  top: 160px;
  right: -50px;
  max-width:max-content;
  overflow-x: hidden;
  width: fit-content;
}
.placeOver6{
  position: absolute;
  top: 220px;
  right: -50px;
  max-width:max-content;
  overflow-x: hidden;
  width: fit-content;
}
.countryDetail h2 {
	font-size: 35px;
	font-weight: 700;
  text-align: center;
}
.countryDetail p {
  color: #cecbd0;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.8px;
    line-height: 1.5em;
}


/*
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}
*/
/************ Footer Css ************/
.footer {
  padding: 20px 0 50px 0;
  margin-bottom: 0px;
  position: relative;
  min-height: 30px;
  height: auto;
  padding: 20px;
    padding-bottom: 2.5rem;    /* Footer height */
  background-image: url('./assets/img/bgJungle2.webp');
  opacity: 0.6;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  
}
/*
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}*/
.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  opacity: 1;  /* O cualquier estilo que esté utilizando en el estado normal */

}
.carousel-control-next, .carousel-control-prev {
  pointer-events: none;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  pointer-events: all;
}

.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
